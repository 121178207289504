body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto !important;
    float: none !important;
}

code {
    font-family: "Roboto", sans-serif;
}

html,
body,
#root {
    height: 100%;
}

#apelio_content_container p {
    color: #707070;
}

#apelio_content_container li {
    color: #707070;
}

#apelio_content_container h2 {
    color: #00539E;
}

#apelio_content_container h3 {
    color: #43A047;
}

#apelio_content_container h4 {
    color: #5FD364;
}

#apelio_content_container figure img {
    border: 5px solid #D5E5F7;
}

#apelio_content_container figcaption {
    text-align: center;
    font-size: 18px;
    color: #A0A0A0;
}

#apelio_content_container figure {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}